<template>
  <div
    v-if="isOpen"
    class="modal fade show"
    id="m_modal_document"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalDocument"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              @click="_close"
            >×</span>
          </button>
        </div>
        <div class="modal-body">
          <div><span v-html="document"></span></div>
        </div>
        <div class="modal-footer">
          <div class="row w-100 mx-auto">
            <div class="col-12 col-lg-6  mx-auto "><button
                @click="_close"
                type="button"
                data-dismiss="modal"
                class="btn m-btn--pill btn-info m-btn m-btn--custom m-btn--bolder m-btn--uppercase w-100"
              >Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DocumentModal",
  props: {
    document: String,
    isOpen: Boolean
  },
  methods: {
    _open () {
      this.$emit("open");
      this.$emit("update:isOpen", true);
    },
    _close () {
      $("#m_modal_document").modal('hide');
      this.$emit("close");
      this.$emit("update:isOpen", false);
    }
  }
}
</script>
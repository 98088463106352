<template>
  <div>
    <AppSubHeader
      link='desvare'
      title='Mi Desvare'
    />
    <main
      class="m-content container mb-5"
      v-if="!(haveDelinquentAccounts || hasPendingFees)"
    >
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="m-portlet m-portlet--full-height m-portlet--mobile m-portlet--rounded mx-2 py-2">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    Desvare
                  </h3>
                  <div
                    class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-left m-dropdown--align-push my-2 px-2"
                    m-dropdown-toggle="hover"
                    aria-expanded="true"
                  >
                    <a
                      href="#"
                      class="m-portlet__nav-link btn btn-secondary m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle"
                    >
                      <i class="la la-ellipsis-v"></i>
                    </a>
                    <div class="m-dropdown__wrapper">
                      <span
                        class="m-dropdown__arrow m-dropdown__arrow--left m-dropdown__arrow--adjust"
                        style="right: auto; left: 10.5px;"
                      ></span>
                      <div class="m-dropdown__inner">
                        <div class="m-dropdown__body">
                          <div class="m-dropdown__content">
                            <p>El desvare es un beneficio de Qnt, para premiar el buen comportamiento de nuestros clientes; En donde puedes pedir un préstamo rápido, para esos gastos imprevistos que se puedan presentar</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-portlet__body pt-2">
              <div class="m-widget3">
                <div class="m-widget3__item">
                  <div class="row">
                    <div class="col-11">
                      <p>
                        Qnt sabe que se pueden presentar imprevistos, en los gastos de nuestros clientes y muchas veces necesitamos un préstamo rápido; Ahora tienes la oportunidad de solicitarlo a QNT. Después de solicitarlo, debes acercarte al punto Efecty más cercano y retirar el monto disponible.
                      </p>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-8 mx-auto">
                      <div class="row product-resume text-center font-weight-bold my-3">
                        <div class="col-12 col-lg-6">
                          <p><label>Monto Aprobado:</label><br /><span class="text-green">{{desvare.total_amount|currency}} COP</span></p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p><label>Monto Disponible para retirar:</label><br /><span class="text-green">{{desvare.amount_to_withdraw|currency}} COP</span></p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p><label>Servicio de administración:</label><br /><span class="text-red">{{desvare.admin_amount | currency}} COP</span></p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p><label>Fecha máxima de Pago de desvare:</label><br /><span class="text-green">{{maxPaymentDate}}</span></p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p><label>Plazo aceptado:</label><br />
                            <form-group
                              :validator="$v.dataForm.term"
                               attribute="el plazo"
                            >
                              <select v-if="desvare.state == 'ACTIVO'" v-model="dataForm.term" ref="term" required>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                              
                              <span v-else class="text-green">
                                  {{desvare.term}}
                              </span>
                            </form-group>
                          </p>
                        </div>
                        <div v-if="paymentValue" class="col-12 col-lg-6">
                          <p><label>Valor de cuota:</label><br /><span class="text-green">{{ paymentValue | currency}} COP</span></p>
                        </div>
                        <div
                          class="col-12 col-lg-6"
                          v-if="desvareWasUsed"
                        >
                          <p><label>Fecha de solicitud:</label><br /><span class="text-green">{{desvare.accepted_at|moment('L')}}</span></p>
                        </div>

                        <div
                          class="col-12 col-lg-6"
                          v-if="desvareWasUsed"
                        >
                          <p v-if="desvare.state == 'PENDIENTE RETIRO'"><label>Fecha máxima para retiro:</label><br /><span class="text-green">{{maxDeliveryDate| moment('L')}}</span></p>
                          <p v-else><label>Fecha de retiro:</label><br /><span class="text-green">{{desvare.delivery_at| moment('L')}}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="m-divider mb-2"><span></span></div>
                      <h3>Retiro</h3>
                      <ol class="my-3 pl-3">
                        <li>Una vez solicitado tu "Desvare" estará disponible en 24 horas; Te notificaremos a través de SMS y correo electrónico.</li>
                        <li>Debes acercarte a cualquier punto Efecty <a
                            href="https://www.google.com/maps/search/efecty"
                            target="_blank"
                            rel="noopener noreferrer"
                          >(Consulta los puntos Efecty cercanos a ti).</a></li>
                        <li>Al punto Efecty, debes llevar tu documento de identidad (El dinero sólo será entregado con este documento).</li>
                        <li>Se te cobrará el valor del desvare junto a la cuota de tu plan de pago con Qnt.</li>
                      </ol>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="desvare.state == 'ACTIVO'"
                  >
                    <div class="col-12">
                      <div class="m-divider mb-2"><span></span></div>
                      <h3>Solicitud</h3>
                      <form
                        class="m-form mt-3"
                        @submit.prevent.stop="submit"
                      >
                        <div class="m-form__group form-group">
                          <div class="m-checkbox-list">

                            <form-group
                              :validator="$v.dataForm.conditions"
                              attribute="los términos y condiciones"
                            >
                              <label class="m-checkbox m-checkbox--check-bold m-checkbox--state-info">
                                <input
                                  v-model="dataForm.conditions"
                                  type="checkbox"
                                  ref="conditions"
                                  required
                                >Aceptación <a
                                  href="#"
                                  @click.prevent="openModalDocument('desvare')"
                                >términos y condiciones Desvare</a>
                                <span></span>
                              </label>
                            </form-group>
                            <form-group
                              :validator="$v.dataForm.personalData"
                              attribute="la autorización de tratamiento de datos"
                            >
                              <label class="m-checkbox m-checkbox--check-bold m-checkbox--state-info">
                                <input
                                  v-model="dataForm.personalData"
                                  type="checkbox"
                                  ref="personal_data"
                                  required
                                >Autorización de <a
                                  href="#"
                                  @click="openModalDocument('autorizacion')"
                                >Tratamiento de datos personales</a>
                                <span></span>
                              </label>
                            </form-group>
                          </div>
                        </div>
                        <div class="row"><button
                            type="button"
                            class="btn m-btn--pill btn-info m-btn m-btn--custom m-btn--bolder m-btn--uppercase col-12 col-lg-6 mx-auto"
                            @click.prevent.stop="submit"
                          >Aceptar desvare</button></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DocumentModal
        :document="document"
        :isOpen.sync="isOpen"
      />
      <SimpleModal
        :document="successMsg"
        :isOpen.sync="successIsOpen"
        labelBtnClose="Ententido"
      />
    </main>
    <main
      class="m-content container mb-5"
      v-else-if="nextFeeList.length"
    >
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="m-portlet m-portlet--full-height ">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    <strong>Para solicitar tu desvare, debes tener un plan de pagos vigente.</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <main
      class="m-content container mb-5"
      v-else
    >
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="m-portlet m-portlet--full-height ">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    <strong>Para solicitar tu desvare, debes estar al día en las cuotas de tu acuerdo de pago.</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import DocumentModal from "@/components/ui/DocumentModal.vue";
import SimpleModal from "@/components/ui/SimpleModal.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: 'UserDesvarePage',
  components: {
    AppSubHeader,
    DocumentModal,
    SimpleModal
  },
  data () {
    return {
      dataForm: { conditions: false, personalData: false},
      document: "<div></div>",
      isOpen: false,
      successMsg: "",
      successIsOpen: true
    }
  },
  validations: {
    dataForm: {
      conditions: { accepted: val => val === true },
      personalData: { accepted: val => val === true },
      term: { accepted: val => val > 0 },
    }
  },
  computed: {
    ...mapState({
      desvare: state => state.products.all,
      generalInformation: state => state.users.generalInformation,
    }),
    ...mapGetters({
      nextFee: 'fees/nextFee',
      nextFeeList: 'fees/nextFeeList',
      hasPendingFees: 'fees/hasPendingFees',
      haveDelinquentAccounts: 'plans/haveDelinquentAccounts',
      desvareWasUsed: 'products/desvareWasUsed',
    }),
    maxPaymentDate () {
      if (this.nextFeeList.length) {
        let today = moment();
        let fee_date = moment(this.nextFee.fecha, 'YYYY-MM-DD');
        const days_for_next_fee = fee_date.diff(today, 'days');
        const nextFee = this.nextFeeList[0];
        const nextNextFee = this.nextFeeList.length > 1 ? this.nextFeeList[1] : nextFee;
        if (days_for_next_fee > 10) {
          return moment(nextFee.fecha, 'YYYY-MM-DD').format('L');
        } else {
          return moment(nextNextFee.fecha, 'YYYY-MM-DD').format('L');
        }
      } else return null;
    },
    maxDeliveryDate () {
      return moment(this.desvare.accepted_at, 'YYYY-MM-DD Z').add(this.desvare.days_to_claim + 1, 'days');
    },
    paymentValue()
    {
        return (this.dataForm.term>0) ? (this.desvare.total_amount / this.dataForm.term) : null;
    }
  },
  methods: {
    async _submit (data) {
      try {
        await this.$store.dispatch("products/createDesvare", data);
        this.successMsg = `¡Felicitaciones ${this.generalInformation.first_name}! Has solicitado el "desvare" de forma exitosa; En 24 Horas estará disponible el monto en los puntos Efecty`;
      } catch (error) {
        this.successMsg = "Ha ocurrido un error procesando su solicitud";
      }
    },
    submit () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.successIsOpen = true;
      this.dataForm["payment_date"] = moment(this.maxPaymentDate).format('YYYY-MM-DD');
      this.dataForm["external_id"] = this.desvare.external_id
      this.successMsg = "Su solicitud esta siendo procesada";
      $("#m_modal_simple").modal('show');
      this._submit(this.dataForm);
    },
    ...mapActions('documents', ['getHtmlDocument']),
    async openModalDocument (typeDocument) {
      this.isOpen = true;
      const document = await this.getHtmlDocument(typeDocument);
      this.document = document.html_content;
      $("#m_modal_document").modal('show');
    }
  },
  created () {
    if (!this.nextFee.fecha) {
      this.$store.dispatch("fees/getAllFees");
    }
    if (!this.desvare.external_id) {
      this.$store.dispatch("products/getQntProducts");
    }
    this.$store.dispatch("parameters/getTermsCredit");
  },
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1.3rem;
  font-weight: 600;
}
li {
  a {
    color: unset;
    text-decoration: underline;
  }
}

label a {
  color: unset;
  text-decoration: underline;
}
.product-resume {
  label {
    font-size: 1.2rem;
    margin-bottom: 0rem;
    font-weight: 600;
    line-height: 1;
  }
  p {
    span {
      font-size: 1.2rem;
    }
  }
}
</style>